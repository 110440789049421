import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
	createStyles({
		hero: {
			marginTop: 60,
			[theme.breakpoints.up('sm')]: {
				marginTop: 125,
			},
		},
		wrapper: {
			marginBottom: 40,
		},
		h1: {
			color: theme.palette.background.paper,
			fontSize: 38,
			fontWeight: 'bold',
			letterSpacing: '2.93px',
			lineHeight: '42px',
			marginBottom: theme.spacing(2),
			[theme.breakpoints.up('lg')]: {
				fontSize: 55,
				lineHeight: '63px',
				marginBottom: 20,
			},
		},
		p: {
			color: theme.palette.background.paper,
			fontSize: 20,
			letterSpacing: '1.01px',
			lineHeight: '30px',
			[theme.breakpoints.up('lg')]: {
				whiteSpace: 'pre-line',
			},
		},
		cloud: {
			right: 0,
			top: 105,
			overflow: 'hidden',
			maxHeight: 426,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				maxHeight: 'none',
				position: 'absolute',
				width: 'auto',
			},
			'& svg': {
				transform: 'translate(-41%, -18%) scale(0.8)',
				[theme.breakpoints.up('sm')]: {
					position: 'static',
					transform: 'translateX(20%)',
				},
				[theme.breakpoints.up('lg')]: {
					transform: 'translateX(0%)',
				},
			},
		},
	})
)
