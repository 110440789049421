export const constants = {
	head: 'Build and Debug in Real Time From Anywhere',
	subhead: 'Seamless Workflow',
	descr: `Sahara's Web IDE and VSCode plugin provides a full environment to build, debug, and run code on devices hosted in our datacenters immediately without disrupting your workflow.`,
	link: { to: 'https://saharacloud.io/register', text: 'Start Building' },

	links: {
		tools: {
			em: 'Tools for every stack',
			p:
				'Client and server libraries from React and PHP to C and Assembly',
			link: { to: 'https://saharacloud.io/register', text: 'Explore libraries' },
		},
		integrations: {
			em: 'Prebuilt integrations',
			p:
				'Integrations to platforms like Github actions, AWS, Airtable, and more.',
			link: { to: 'https://saharacloud.io/register', text: 'Find integrations' },
		},
	},
	styles: {
		subHead: {
			color: '#FE9AD3',
			fontWeight:'bold',
		},
		descr: {
			color: '#fff',
		},
		title: {
			color: '#fff',
		},
		link: {
			background: `linear-gradient(180deg, #FEAEDC 0%, #FE9AD3 100%);`,
		},
	}
}
