// for hardcoded values of reset page

const constants = {
  main: "Reset your Password",
  logo_text: "Sahara",
  password1_hint: "New password",
  password1_error: "Invalid password. Must contain 1 lowercase, uppercase, numeric, special character. Minimum length is 8 characters.",
  password2_hint: "Re-type new password",
  password2_error: "Passwords must match.",
  reset_btn: "Reset Password",
  password_regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  error_msg1: "Please fill out all required information.",
  error_msg2: "Password reset failed. Please generate a new link and try again.",
  success_msg: "Password reset. You will be redirected to login shortly."
};

module.exports = {
    constants: constants
};