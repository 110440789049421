import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { constants } from '../header'
import { Link } from 'react-scroll'
import { createHref } from '../../utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	href: {
		color: '#b8b7ad',
		textDecoration: 'none',
	},
	ul: {
		'&:focus': {
			outline: 'none'
		}
	},
	li: {
		width: 'max-content',
		cursor: 'pointer',
		'&:hover':{
			color: theme.palette.background.paper,
		}, 
		'& + li': {
			marginTop: theme.spacing(1),
		},
	},
}))

export const Sidebar = (props) => {
	const classes = useStyles()

	return (
		// Pass on our props
		<Menu {...props} right>
			<ul className={classes.ul}>
				{constants.nav.map((link) =>
					link.href ? (
						<li className={classes.li} tabIndex='0' key={link.text}>
							<a href={link.href} className={classes.href}>
								{link.text}
							</a>
						</li>
					) : (
						<li className={classes.li} tabIndex='0' key={link.text}>
							<Link
								to={createHref(link.text)}
								spy={true}
								smooth={true}
								duration={500}
								onClick={() => props.setMenuOpen(false)}
							>
								{link.text}
							</Link>
						</li>
					)
				)}
			</ul>
		</Menu>
	)
}
