import React from 'react';
import { useHistory } from 'react-router-dom';
import { constants } from './SendEmailForReset_constants.js';
import sty from './SendEmailForReset_styles.js';

import './SendEmailForReset.scss';

//import images
import cloud from '../../resources/cloud.png';
import Waves from "../waves/waves";

const requestFunctions = require('../../utils/request.helpers');

function SendEmailForReset(props) {
  const dims = props.dims;
  const targets = props.targets;
  const toast = props.toast;
  const styles = sty(dims.width, dims.height);

  const [emailValid, setEmailValid] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const history = useHistory();

  //validate inputted email
  const handleEmailInput = (e) => {
    let n_email = e.target.value,
      isValid = n_email.match(constants.email_regex) != undefined;

    setEmail(n_email);
    setEmailValid(isValid);
  }

  //handler for clicking return to sign in
  const handleReturnToSignIn = (e) => {
    history.push('login');
  }

  //handler for clicking Send password reset request
  //initiate reset process by sending email
  const handleSendResetRequest = (e) => {
    if (emailValid) {
      let body = {userEmail: email},
        targ = targets.auth + "/reset";

      requestFunctions.makeRequestBody("POST", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch(() => {
          setIsError(true);
        });
    }
    else {
      setIsError(true);
    }
  }

  const handleCloseIncorrectCredentialsAlert = (e) => {
    setIsError(false);
  }

  return (
    <div className="login-index">
      <div className="page reset">
        <div className="center-container">
          <div className="logo-wrapper">
            <img src={cloud} alt="cloud" className="logo-cloud"  />
            <span className="logo-text" >{constants.logo_text}</span>
          </div>
          <p className="title" >{constants.main}</p>
          <div className="invalid-credentials-block">
            {isError ? <div className="invalid-credentials">
              <span className="invalid-credentials-text">{constants.error_msg_reset1}</span>
              <span className="cross" onClick={handleCloseIncorrectCredentialsAlert}>✖</span>
            </div> : <></>}
          </div>
          {!isEmailSent ?
            (<div className="form">
            <div className="inputs">
              <div className="input-wrapper">
                <div className="input-wrapper">
                  <input type="text" className={`input`} value={email} onChange={handleEmailInput} placeholder={constants.email_hint} />
                </div>
              </div>
            </div>
            <button type="button" className="yellow" onClick={handleSendResetRequest} >{constants.send_reset_request_btn}</button>
          </div>)
            :
            (<div className="form">
              <div className="inputs">
                 <span className="success_email_send">{constants.success_email_send}</span>
              </div>
              <button type="button" className="yellow" onClick={handleReturnToSignIn} >{constants.return_to_sign_in}</button>
            </div>)
          }
        </div>
        <Waves />
      </div>
    </div>
  );
}
export default SendEmailForReset;
