import React from 'react'
import { useStyles } from './HeaderStyles'
import { constants } from './HeaderConstants'
import { createHref } from '../../utils'
import Container from '@material-ui/core/Container'
import logo from '../../resources/logo.png'
import Box from '@material-ui/core/Box'
import { BurgerMenu } from '../ui'
import { Link } from 'react-scroll'

export const Header = ({ onMenuOpen, showHeader }) => {
	const classes = useStyles()

	switch (showHeader) {
		case false:
			return '';
		default:
			return (
				<Container maxWidth='lg'>
					<header className={classes.header}>
						<a href='/' className={classes.logo}>
							<img src={logo} alt='logo' />
						</a>
						<nav className={classes.nav}>
							<Box display={{ xs: 'none', md: 'block' }}>
								<ul>
									{constants.nav.map((link) => (
										<li key={link.text}>
											{link.href ? (
												<a href={link.href}>{link.text}</a>
											) : (
													<Link
														to={createHref(link.text)}
														spy={true}
														smooth={true}
														duration={500}
													>
														{link.text}
													</Link>
												)}
										</li>
									))}
								</ul>
							</Box>
							<Box display={{ xs: 'block', md: 'none' }}>
								<BurgerMenu onMenuOpen={onMenuOpen} />
							</Box>
						</nav>
					</header>
				</Container>
			)
	}
}
