// for hardcoded styling of verify page

const views = require('../../utils/view.helpers');

//inputs: current screen width and height
//returns appropriate style objects for elements
function sty (w, h) {
    const wScale = views.scale(w, true);
    const hScale = views.scale(h, false);
    return {
        main: {width: "100%", height: "100vh", backgroundColor: "#ffe880", position: "relative"},
        center: {"position": "absolute", "left": "25%", "width": "50%", "top": "25%", "height": "50%", "backgroundColor":"#ffffff", borderRadius: views.fontSize(10, hScale)},
        logo_img: {position: "absolute", left: views.setDim(37, hScale), top: views.setDim(10, wScale), width: views.setDim(4, hScale), height: views.setDim(6.4, wScale), backgroundBlendMode: "screen"},
        logo_text: {position: "absolute", left: views.setDim(42, hScale), top: views.setDim(9, wScale), width: views.setDim(27, hScale), height: views.setDim(8, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(25, hScale), fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.25, hScale), color: "#ffd149"},
        main_txt: {position: "absolute", left: views.setDim(16, hScale), top: views.setDim(14, wScale), width: views.setDim(60, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(30, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(0.87, hScale), color: "#1a1b27", textAlign: "center"},
        code_input: {
            valid: {position: "absolute", left: views.setDim(31, hScale), top: views.setDim(44, wScale), width: views.setDim(30, hScale), height: views.setDim(8, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #ffd149", backgroundColor: "#ffffff", textAlign: "center"},
            invalid: {position: "absolute", left: views.setDim(31, hScale), top: views.setDim(44, wScale), width: views.setDim(30, hScale), height: views.setDim(8, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #cc0000", backgroundColor: "#ffffff", textAlign: "center"}
        },
        code_error: {
            valid: {display: "none"},
            invalid: {position: "absolute", left: views.setDim(30, hScale), top: views.setDim(55, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#cc0000"} 
        },
        submit_btn: {position: "absolute", left: views.setDim(34, hScale), top: views.setDim(64, hScale), width: views.setDim(25, hScale), height: views.setDim(8, hScale), borderRadius: views.fontSize(10, hScale), backgroundColor: "#ffd149", cursor: "pointer"},
        submit_btn_txt: {fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.3, hScale), color: "#ffffff"},
        resend_btn: {position: "absolute", left: views.setDim(34, hScale), top: views.setDim(75, hScale), width: views.setDim(25, hScale), height: views.setDim(8, hScale), borderRadius: views.fontSize(10, hScale), backgroundColor: "#ffffff", cursor: "pointer"},
        resend_btn_txt: {fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.3, hScale), color: "#ffd149"}
    };
}

export default sty;