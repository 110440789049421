import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		marginTop: 140,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.up('sm')]: {
			marginTop: 240,
		},
		[theme.breakpoints.up('lg')]: {
			marginTop: 274,
		},
	},
	title: {
		marginBottom: 60,
	},
	contentWrapper: {
		paddingLeft: theme.spacing(0),
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(10),
		},
	},
	coverWrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		'& img': {
			alignSelf: 'self-start',
			border: '1px solid #B7ECFC',
			boxShadow: '12px 12px 11px 0 rgba(114,124,135,0.17)',
			borderRadius: 6,
			//width: '100%',
		},
		'& span': {
			marginTop: 20,
			alignSelf: 'center',
			color: '#89B0D7',
			fontSize: 18,
			fontWeight: 'bold',
		},
	},
	coverImg: {
		position: 'absolute',
		right: 0,
		bottom: 10,
	},

  sectionImage: {
    borderRadius: '20px',
  }
}))
