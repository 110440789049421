import React from 'react'
import Box from '@material-ui/core/Box'
import { joinUsFormStyles } from '../form'
import { useStyles } from './JoinStyles'
import { Form } from '../form'
import { constants } from './JoinConstants'

const Join = () => {
	const classes = useStyles()

	return (
		<section className={classes.wrapper} id='betaList'>
			<Box display='flex' justifyContent='center'>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					className={classes.box}
				>
					<h5 className={classes.title}>{constants.title}</h5>
					<p className={classes.descr}>{constants.descr}</p>
					<Form styles={joinUsFormStyles} />
				</Box>
			</Box>
		</section>
	)
}

export { Join }
