import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ReadMoreLink } from './ReadMoreLink'

export const ReadMore = ({ links }) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<ReadMoreLink content={links.tools} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<ReadMoreLink content={links.integrations} />
			</Grid>
		</Grid>
	)
}
