import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	title: {
		color: (props) => props.color,
		fontSize: 32,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '44px',
		[theme.breakpoints.up('lg')]: {
			fontSize: 40,
			lineHeight: '60px',
		},
	},
}))

const Title = ({ children, component = 'h2', color, align }) => {
	const classes = useStyles(color)
	return (
		<Typography className={classes.title} component={component} align={align}>
			{children}
		</Typography>
	)
}

Title.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	component: PropTypes.string,
	color: PropTypes.object,
	align: PropTypes.string,
}

export { Title }
