import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	link: (props) => ({
		...props,
    background: '#FFFFFF',
		alignSelf: 'self-start',
		padding: '15px 30px',
		borderRadius: '15px',
		color: '#1A1E2A',
		fontSize: '22px',
		fontWeight: 'bold',
		lineHeight: '30px',
		display: 'inline-block',
		textDecoration: 'none',
		'&:hover': {
			opacity: 0.85,
		},
	}),
  img_slack: (props) => ({
    verticalAlign: 'middle',
	}),
}))

const SlackLink = ({ href, styles, children }) => {
	const classes = useStyles(styles)

	return (
		<a href={href} className={classes.link}>
      <img className={classes.img_slack} src="/slack_icon.png" alt="Slack" style={{width: "30px", height: "30px"}} />
			{children}
		</a>
	)
}

export { SlackLink }
