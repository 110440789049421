// for hardcoded values of verify page

const constants = {
  main: "Verify your Email",
  logo_text: "Sahara",
  code_hint: "Enter code",
  code_error: "Invalid code. Must be 6 alphanumeric characters.",
  submit_btn: "Submit",
  resend_btn: "Re-Send Code",
  code_regex: /^[A-Za-z0-9]{6}$/,
  error_msg_code1: "Please enter a valid code.",
  error_msg_code2: "Code was not accepted. Please ensure that you have entered the correct information.",
  error_msg_resend: "Failed to re-send code. Please try again later.",
  success_msg_resend: "New code has been sent. Please check your email for a new link.",
  success_msg_verify: "Email verified. You will be redirected to login shortly."
};

module.exports = {
    constants: constants
};