export const constants = {
	head: 'Verify Your Entire System Using Our Virtual Lab',
	subhead: 'Comprehensive Testing Environment',
	descr: `Sahara's Virtual Lab allows you to debug, trace, and write unit and integration tests online.  \n Our Oscilliscopes \
	and Logic Analyzers ensure your system reflects reality by following \
  signals from cloud to silicon that can translate to production.`,
	link: { to: 'https://saharacloud.io/register', text: 'Start Testing Your System' },
	coverCard: {
		em: 'Project V1',
		p: `Whether it's the newest sensor or a powerful processor to develop your
		product, we've got you covered.`,
	},
	styles: {
		subHead: {
			color: '#FAC80E',
			fontWeight: 'bold',
		},
		descr: {
			color: '#32325C',
		},
		title: {
			color: '#32325C',
		},
		link: {
			background: `linear-gradient(180deg, #FFEC89 0%, #FFD158 100%);`,
		},
	},
}
