import React from 'react'
import { useStyles } from './PricingStyles'
import { Title } from '../ui'
import { constants } from './PricingConstants'
import { withTriangles } from '../hoc'
import { PriceCard } from '../priceCard'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

const Pricing = () => {
	const classes = useStyles()

	return (
		<section className={classes.wrapper} id='pricing'>
			<Container maxWidth='lg'>
				<div className={classes.titleWrapper}>
					<Title
						component='h2'
						color={{ color: constants.styles.title.color }}
						align='center'
					>
						Pay for What You Use
					</Title>
					<Title
						component='h2'
						color={{ color: constants.styles.title.color }}
						align='center'
					>
						Choose Your Usage Plan
					</Title>
				</div>
				<Grid container spacing={30}>
					{constants.priceCards.map((option, index) => (
						<Grid
							item
							xs={12}
							lg={4}
							style={{ display: 'flex', justifyContent: 'left' , textAlign:'left'}}
							key={index}
						>
							<PriceCard options={option} />
						</Grid>
					))}
				</Grid>
			</Container>
		</section>
	)
}

export default withTriangles({ topTriangle: true })(Pricing)
