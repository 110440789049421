import React from 'react'
import { useStyles } from './HardwareStyles'
import { Row, TextContent } from '../ui'
import Container from '@material-ui/core/Container'
import { constants } from './HardwareConstants'
import testImg from '../../resources/testing_short.gif'

const Hardware = () => {
	const classes = useStyles()

	const coverCard = (
		<div className={classes.coverCard}>
      <div className={classes.imgWrapper}>
			<img src ={testImg}   width="680"
     height="390"/>
     </div>
		</div>
	)

	const textContent = (
		<div className={classes.contentWrapper}>
			<TextContent options={constants} />
		</div>
	)

	return (
		<section className={classes.wrapper}>
			<Container maxWidth='lg'>
				<Row leftColumn={() => coverCard} rightColumn={() => textContent} />
			</Container>
		</section>
	)
}

export { Hardware }
