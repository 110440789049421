// for hardcoded values of register page

const constants = {
  right_main: "Sign Up and start building",
  logo_text: "Sahara",
  name_hint: "Name",
  name_error: "Name cannot be empty.",
  email_hint: "Email address",
  email_error: "Invalid email address",
  password1_hint: "Password",
  password1_error: "Invalid password. Must contain 1 lowercase, uppercase, numeric, special character. Minimum length is 8 characters.",
  password2_hint: "Re-type password",
  password2_error: "Passwords must match.",
  login_btn: "Sign In",
  register_btn: "Create Account",
  email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password_regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  error_msg1: "Please fill out all required information",
  error_msg2: "Registration failed! Please try again later",
  success_msg: "Account created. Please check your email to verify your account. You will be redirected to the verify page shortly."
};

module.exports = {
    constants: constants
};
