import flow from 'lodash/flow'
import curryRight from 'lodash/curryRight'
import lowerFirst from 'lodash/lowerFirst'
import split from 'lodash/split'
import map from 'lodash/map'
import join from 'lodash/join'
import upperFirst from 'lodash/upperFirst'

export const createHref = (str) => {
  return ''.concat(
    flow(
      curryRight(split)(' ', str.length),
      curryRight(map)(upperFirst),
      curryRight(join)(''),
      lowerFirst,
    )(str)
  )
}
