export const palette = {
	gradients: {
		pinkGradient: `linear-gradient(180deg, #FFBDE2 0%, #FF92D0 100%)`,
		blueGradient: `linear-gradient(180deg, #A0EFFF 0%, #67E5FF 100%)`,
		yellowGradient: `linear-gradient(180deg, #FFEC89 0%, #FFD158 100%)`,
	},
	shadows: {
		pinkShadow: `0 8px 34px 0 rgba(255,191,219,0.32)`,
		blueShadow: `0 8px 34px 0 rgba(112,214,254,0.32)`,
		yellowShadow: `0 8px 34px 0 rgba(247,181,0,0.32)`,
	},
	colors: {
		darkBlue: '#202236',
		purple: '#32325C',
		lilac: '#5B6C94',
		pink: '#FF6BBF',
		blue: '#21CBED',
		yellow: '#FFD159',
	},
}
