// for hardcoded values of footer component of landing page UI

export const constants = {
	title_big: 'Follow us on Social Media',
	title_small: [
		'Sahara cloud is currently in development.',
	],
	media_title: 'Follow us on Social Media',
	media: [
		{ name: 'Facebook', link: 'https://www.facebook.com/SaharaCloud.io/' },
		{ name: 'Twitter', link: 'https://twitter.com/sahara_cloud' },
		{ name: 'LinkedIn', link: 'https://www.linkedin.com/company/haracloud/' },
	],
	copyright: '©Copyright 2022 Sahara. All Rights Reserved.',
}
