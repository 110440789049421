// component for reset

import React from 'react';
import { constants } from './reset_constants.js';
import sty from './reset_styles.js';

import './reset.scss';

//import images
import cloud from '../../resources/cloud.png';
import Waves from "../waves/waves";

const requestFunctions = require('../../utils/request.helpers');

function Reset (props) {

    const [password1, setPassword1] = React.useState("");
    const [password1Valid, setPassword1Valid] = React.useState(true);
    const [password2, setPassword2] = React.useState("");
    const [password2Valid, setPassword2Valid] = React.useState(true);
    const [newLoad, setNewLoad] = React.useState(true);
    const [submit, setSubmit] = React.useState(false);

    //parent passes base URL for auth
    //parent passes redirect uri from query string if present
    const targets = props.targets;
    const id = props.id;
    const code = props.code;
    const dims = props.dims;
    const toast = props.toast;

    const styles = sty(dims.width, dims.height);

    //validate inputted password
    const handlePassword1Input = (e) => {
        let n_pass = e.target.value,
            isValid = n_pass.match(constants.password_regex) != undefined;

        setPassword1(n_pass);
        setPassword1Valid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //validate inputted password
    const handlePassword2Input = (e) => {
        let n_pass = e.target.value,
            isValid = n_pass === password1;

        setPassword2(n_pass);
        setPassword2Valid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //handler for password reset
    const handleReset = (e) => {
        if (!newLoad && password1Valid && password2Valid) {
            setSubmit(true);
            let body = {userId: id, code: code},
            targ = targets.auth + "/reset";

            requestFunctions.makeRequestBody("PUT", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
                .then((body) => {
                    toast.success(constants.success_msg, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false
                    });

                    let targ = targets.stat + "/login";
                    setTimeout(function () { window.location.replace(targ); }, 5000);
                })
                .catch((err) => {
                    setSubmit(false);
                    toast.error(constants.error_msg2, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false
                    });
                });
        }
        else {
           toast.error(constants.error_msg1, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
            });
        }
    }

    return (
      <div className="login-index">
        <div className="page reset">
          <div className="center-container">
            <div className="logo-wrapper">
              <img src={cloud} alt="cloud" className="logo-cloud"  />
              <span className="logo-text" >{constants.logo_text}</span>
            </div>
            <p className="title" >{constants.main}</p>
            <div className="form">
              <div className="inputs">
                <div className="input-wrapper">
                  <input type="password" className={`input ${password1Valid ? 'valid' : 'invalid mb-55'}`} disabled={submit} value={password1} onChange={handlePassword1Input} placeholder={constants.password1_hint} />
                  <span className={`input-error ${password1Valid ? 'valid' : 'invalid'}`}>{constants.password1_error}</span>
                </div>
                <div className="input-wrapper">
                  <input type="password" className={`input ${password2Valid ? 'valid' : 'invalid'}`} disabled={submit} value={password2} onChange={handlePassword2Input} placeholder={constants.password2_hint} />
                  <span className={`input-error ${password2Valid ? 'valid' : 'invalid'}`}>{constants.password2_error}</span>
                </div>
              </div>
              <button type="button" className="yellow" onClick={handleReset} >{constants.reset_btn}</button>
            </div>
          </div>
          <Waves />
        </div>
        </div>
      );
}

export default Reset;
