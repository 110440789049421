// for hardcoded values of header component of landing page UI

export const constants = {
	nav: [
		 { text: 'How it works' },
		 { text: 'Pricing' },
		 { text: 'About', href: 'https://www.notion.so/Careers-Sahara-fb77f754990343de930886b32235dd1d' },
		 { text: 'Blog', href: 'https://saharacloud.io/blog' },
     { text: 'Login', href: 'https://saharacloud.io/login' },
	],
}
