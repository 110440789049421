import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		maxWidth: (props) => props.form.maxWidth,
		width: '100%',
		position: 'relative',
		'& button:hover:disabled': {
			cursor: 'not-allowed',
		},
		'& > button, & > input': {
			borderRadius: (props) => props.borderRadius,
		},
	},
	input: (props) => ({
		...props.input(theme),
		width: '100%',
		border: 'none',
		padding: '13px 55% 13px 17px',
	}),
	button_submitted: (props) => ({
		...props.button(theme),
		background: 'linear-gradient(180deg, #8C61F6 0%, #FFD158 100%)',
		border: 'none',
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.1)',
		cursor: 'pointer',
		width: '54%',
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
		padding: '12px 17px',
		fontWeight: 'bold',
	}),
	button_normal: (props) => ({
		...props.button(theme),
		background: 'linear-gradient(180deg, #8C61F6 0%, #6B47E1 100%)',
		border: 'none',
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.1)',
		cursor: 'pointer',
		width: '54%',
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
		padding: '12px 17px',
		fontWeight: 'bold',
	}),
}))

export const heroFormStyles = {
	form: {
		maxWidth: 507,
	},
	input(theme) {
		return {
			background: 'rgba(255, 255, 255, 0.11)',
			opacity: 0.5,
			color: '#FFFCF8',
			fontSize: 18,
			fontWeight: 'bold',
			letterSpacing: -0.35,
			lineHeight: 'normal',
			[theme.breakpoints.up('sm')]: {
				fontSize: 22,
				lineHeight: '30px',
			},
			'&:focus': {
				outline: 'none',
				boxShadow: '1px 1px 20px 0px rgb(129 89 239);',
			},
		}
	},
	button(theme) {
		return {
			background: 'linear-gradient(180deg, #8C61F6 0%, #6B47E1 100%)',
			color: '#FFFFFF',
			fontSize: 18,
			fontWeight: 'bold',
			lineHeight: 'normal',
			[theme.breakpoints.up('sm')]: {
				fontSize: 22,
				lineHeight: '30px',
			},
		}
	},
	borderRadius: '29.5px',
}

export const joinUsFormStyles = {
	form: {
		maxWidth: 417,
	},
	input() {
		return {
			fontSize: '18px',
			letterSpacing: '-0.34px',
			background: 'rgba(255, 255, 255, 0.2)',
			color: '#fff',
			'&::placeholder': {
				color: '#fff',
			},
			'&:focus': {
				outline: 'none',
				boxShadow: 'rgb(252 255 70) 1px 1px 20px 0px;',
			},
		}
	},
	button(theme) {
		return {
			background: 'linear-gradient(180deg, #FFEC89 0%, #FFD158 100%)',
			boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
			color: '#000',
			fontSize: 18,
			letterSpacing: '-0.32px',
		}
	},
	borderRadius: '24px',
}
