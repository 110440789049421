import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const HEIGHT = 170

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		'&.topTriangle::before, &.bottomTriangle::after': {
			content: '""',
			display: 'block',
			width: '100%',
			height: HEIGHT / 2,
			position: 'absolute',
			right: 0,
			background: theme.palette.background.paper,
			[theme.breakpoints.up('sm')]: {
				height: HEIGHT,
			},
		},
		'&.bottomTriangle::after': {
			bottom: -HEIGHT / 2 + 1,
			clipPath: 'polygon(0 0, 0 100%, 100% 0)',
			[theme.breakpoints.up('sm')]: {
				bottom: -HEIGHT + 1,
			},
		},

		'&.topTriangle::before': {
			top: -HEIGHT / 2 + 1,
			clipPath: 'polygon(0 100%, 100% 100%, 100% 0%)',
			[theme.breakpoints.up('sm')]: {
				top: -HEIGHT + 1,
			},
		},
	},
}))

export const withTriangles = ({
	topTriangle = false,
	bottomTriangle = false,
}) => (Component) => {
	return (props) => {
		const classes = useStyles()

		return (
			<div
				className={classNames(classes.root, { topTriangle, bottomTriangle })}
			>
				<Component {...props} />
			</div>
		)
	}
}
