// for hardcoded styling of register page

const views = require('../../utils/view.helpers');

//inputs: current screen width and height
//returns appropriate style objects for elements
function sty (w, h) {
    const wScale = views.scale(w, true);
    const hScale = views.scale(h, false);
    return {
        main: {width: "100%", height: "100vh", backgroundColor: "#ffffff", position: "relative"},
        left: {"position": "absolute", "width": "50%", "height": "100vh", "backgroundColor":"#ffffff"},
        right_main: {position: "absolute", left: views.setDim(10, hScale), top: views.setDim(18, wScale), width: views.setDim(80, hScale), height: views.setDim(6.9, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(30, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(0.87, hScale), color: "#1a1b27", textAlign: "center"},
        right_second: {position: "absolute", left: views.setDim(24.58, hScale), top: views.setDim(28, wScale), width: views.setDim(52.36, hScale), height: views.setDim(2.02, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(18, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(0.52, hScale), color: "#1a1b27", textAlign: "center"},
        code_img: {position: "absolute", left: views.setDim(22, hScale), top: views.setDim(45, wScale), width: views.setDim(65, hScale), height: views.setDim(45, wScale), backgroundBlendMode: "screen"},
        right: {"position": "absolute", "left": "50%", "width": "50%", "height": "100%", "backgroundColor":"#ffe880"},
        logo_img: {position: "absolute", left: views.setDim(34, hScale), top: views.setDim(15.8, wScale), width: views.setDim(4.44, hScale), height: views.setDim(3.5, wScale), backgroundBlendMode: "screen"},
        logo_text: {position: "absolute", left: views.setDim(40, hScale), top: views.setDim(15, wScale), width: views.setDim(27, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(25, hScale), fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.25, hScale), color: "#ffd149"},
        register_big_txt: {position: "absolute", left: views.setDim(37, hScale), top: views.setDim(20, wScale), width: views.setDim(40, hScale), height: views.setDim(25, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(30, hScale), fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.23, hScale), color: "#1a1b27"},
        register_small_txt: {position: "absolute", left: views.setDim(30, hScale), top: views.setDim(28, wScale), width: views.setDim(40, hScale), height: views.setDim(25, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(15, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#1a1b27"},
        name_input: {
            valid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(35, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #ffd149", backgroundColor: "#ffffff", textAlign: "center"},
            invalid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(35, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #cc0000", backgroundColor: "#ffffff", textAlign: "center"}
        },
        name_error: {
            valid: {display: "none"},
            invalid: {position: "absolute", left: views.setDim(38, hScale), top: views.setDim(41, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#cc0000"} 
        },
        email_input: {
            valid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(45, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #ffd149", backgroundColor: "#ffffff", textAlign: "center"},
            invalid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(45, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #cc0000", backgroundColor: "#ffffff", textAlign: "center"}
        },
        email_error: {
            valid: {display: "none"},
            invalid: {position: "absolute", left: views.setDim(38, hScale), top: views.setDim(51, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#cc0000"} 
        },
        password1_input: {
            valid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(55, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #ffd149", backgroundColor: "#ffffff", textAlign: "center"},
            invalid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(55, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #cc0000", backgroundColor: "#ffffff", textAlign: "center"}
        },
        password1_error: {
            valid: {display: "none"},
            invalid: {position: "absolute", left: views.setDim(10, hScale), top: views.setDim(61, wScale), width: views.setDim(80, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#cc0000"} 
        },
        password2_input: {
            valid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(65, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #ffd149", backgroundColor: "#ffffff", textAlign: "center"},
            invalid: {position: "absolute", left: views.setDim(28, hScale), top: views.setDim(65, wScale), width: views.setDim(35, hScale), height: views.setDim(5, wScale), borderRadius: views.fontSize(10, hScale), border: "solid " + views.fontSize(1, hScale) + " #cc0000", backgroundColor: "#ffffff", textAlign: "center"}
        },
        password2_error: {
            valid: {display: "none"},
            invalid: {position: "absolute", left: views.setDim(38, hScale), top: views.setDim(71, wScale), width: views.setDim(80, hScale), height: views.setDim(5, wScale), fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "300", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.33", letterSpacing: views.fontSize(-0.32, hScale), color: "#cc0000"} 
        },
        register_btn: {position: "absolute", left: views.setDim(33, hScale), top: views.setDim(75, hScale), width: views.setDim(25, hScale), height: views.setDim(5, hScale), borderRadius: views.fontSize(10, hScale), backgroundColor: "#ffd149", cursor: "pointer"},
        register_btn_txt: {fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.3, hScale), color: "#ffffff"},
        login_btn: {position: "absolute", left: views.setDim(33, hScale), top: views.setDim(82, hScale), width: views.setDim(25, hScale), height: views.setDim(5, hScale), borderRadius: views.fontSize(10, hScale), backgroundColor: "#ffffff", cursor: "pointer"},
        login_btn_txt: {fontFamily: "Open Sans", fontSize: views.fontSize(12, hScale), fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: views.fontSize(-0.3, hScale), color: "#ffd149"},
        
    };
}

export default sty;