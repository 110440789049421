import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	wrapper: {
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {
      marginTop: 90,
    },
  },
	textWrapper: {
		padding: theme.spacing(2),
		paddingTop: 0,
	},
	readMoreWrapper: {
		marginTop: theme.spacing(6),
	},
	coverCard: {
		padding: '10px 20px',
		[theme.breakpoints.up('md')]: {
			maxWidth: 590,
		},
		'& em': {
			fontSize: 18,
			fontWeight: 600,
			letterSpacing: -0.14,
			lineHeight: '40px',
			color: theme.palette.background.paper,
		},
		'& p': {
			opacity: 0.7,
			color: '#FFFFFF',
			fontSize: 14,
			letterSpacing: '1.01px',
			lineHeight: '22px',
			[theme.breakpoints.up('lg')]: {
				fontSize: 18,
				lineHeight: '29px',
			},
		},
	},
	imgWrapper: {
		background: '#1A1E2A',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
	},

  heroImage: {
    borderRadius: '20px',
  }
}))
