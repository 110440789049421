import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	em: {
		color: '#FFFFFF',
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
	},
	p: {
		opacity: 0.7,
		color: '#FFFFFF',
		fontSize: 15,
		letterSpacing: '0.84px',
		lineHeight: '29px',
		marginBottom: theme.spacing(2),
	},
	link: {
		color: '#FE9AD3',
		fontSize: 18,
		fontWeight: 'bold',
		textDecoration: 'none',
	},
}))

export const ReadMoreLink = ({ content }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<em className={classes.em}>{content.em}</em>
			<p className={classes.p}>{content.p}</p>
			<a href={content.link.to} className={classes.link}>
				{content.link.text}
			</a>
		</div>
	)
}
