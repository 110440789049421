import React from 'react'
import { useStyles } from './PriceCardStyles'
import { Link } from '../ui'
import classNames from 'classnames'

const PriceCard = ({ options }) => {
	const classes = useStyles(options)

	return (
		<div
			className={classNames(classes.priceCard, { active: options.isActive })}
		>
			<strong className={classes.rate}>{options.rate}</strong>
			<strong className={classes.price}>{options.price.value}</strong>
			<span className={classes.span} style={{ fontWeight: 'bold' }}>{options.price.per}</span>
			<ul className={classes.ul}>
				{options.list.map((textItem) => {
					return <li key={textItem}>{textItem}</li>
				})}
			</ul>
			<div className={classes.linkWrapper}>
				<Link href={options.link.to} styles={options.link.styles}>
					{options.link.text}
				</Link>
			</div>
		</div>
	)
}

export { PriceCard }
