// for hardcoded values of main component of landing page UI

export const constants = {
	email_hint: 'Enter email',
	email_side: 'Get Early Access',
	email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	email_error: 'Invalid email address',
	support_msg: 'Questions? Email ',
	support_email: 'andy@saharacloud.io',
	success_msg: 'Email saved!',
	error_msg1: 'Please enter a valid email address.',
	error_msg2: 'Sorry, something went wrong!',
	mailChimpTarget:
		'https://haracloud.us4.list-manage.com/subscribe/post?u=dc102f35a3ad803c2b4eaea50&id=6a64b66162',
	feedback: 'Feedback? Email ',
	email: 'andy@saharacloud.io',
}
