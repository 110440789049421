// component for login

import React from 'react';
import { constants } from './login_constants.js';
import sty from './login_styles.js';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';

import './login.scss';

//import images
import cloud from '../../resources/cloud.png';
import Run_Job_Final from '../../resources/Run_Job_Final.gif';
import Waves from "../waves/waves";

const requestFunctions = require('../../utils/request.helpers');

function Login (props) {

    const [email, setEmail] = React.useState("");
    const [emailValid, setEmailValid] = React.useState(true);
    const [password, setPassword] = React.useState("");
    const [passwordValid, setPasswordValid] = React.useState(true);
    const [newLoad, setNewLoad] = React.useState(true);
    const [submit, setSubmit] = React.useState(false);
    const [isCredentialsInvalid, setIsCredentialsInvalid] = React.useState(false);

    //parent passes base URL for auth
    //parent passes redirect uri from query string if present
    const targets = props.targets;
    const redirectUri = props.redirectUri;
    const dims = props.dims;
    const toast = props.toast;

    const styles = sty(dims.width, dims.height);

    const history = useHistory();

    //validate inputted email
    const handleEmailInput = (e) => {
        let n_email = e.target.value,
            isValid = n_email.match(constants.email_regex) != undefined;

        setEmail(n_email);
        setEmailValid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //validate inputted password
    const handlePasswordInput = (e) => {
        let n_pass = e.target.value,
            isValid = n_pass.match(constants.password_regex) != undefined;

        setPassword(n_pass);
        setPasswordValid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //handler for user login
    const handleLogin = (e) => {
        if (!newLoad && emailValid && passwordValid) {
            setSubmit(true);
            let body = {userEmail: email, userPassword: password},
            targ = targets.auth + "/jwt",
            jwt;

            requestFunctions.makeRequestBody("POST", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
                .then((jwt) => {
                    const cookies = new Cookies();
                    cookies.set('auth', jwt, { path: '/' , sameSite: "none", secure: true });

                    let targ = targets.base + "/" + ((redirectUri == null) ? "workspace" : redirectUri);
                    window.location.replace(targ);
                })
                .catch((err) => {
                  setIsCredentialsInvalid(true);
                  setSubmit(false);
                });
        }
        else {
          setIsCredentialsInvalid(true);
          setSubmit(false);
        }
    }

    //handler for registration
    //redirect to register page
    const handleRegister = (e) => {
        window.location.replace(targets.stat + "/register");
    }

    const handleCloseIncorrectCredentialsAlert = (e) => {
      setIsCredentialsInvalid(false);
    }

    //handler for clicking forgot password link
    //redirect to send reset email page
    const handleForgotPass = (e) => {
      history.push('resetPassword');
    }

    return (
        <div className="login-index">
      <div className="page row login">
          <div className="page-left login-left">
              <div className="container">
                  <p className="main-title" >{constants.left_main1}</p>
                  <p className="main-title" >{constants.left_main2}</p>
                  <img src={Run_Job_Final} alt="p1" className="p1-img" />
              </div>
          </div>
          <div className="page-right login-right">
              <div className="logo-wrapper">
                  <img src={cloud} alt="cloud" className="logo-cloud" />
                  <span className="logo-text">{constants.logo_text}</span>
              </div>

              <div className="form">
                  <div className="invalid-credentials-block">
                    {isCredentialsInvalid ? <div className="invalid-credentials">
                      <span className="invalid-credentials-text">{constants.incorrect_credentials}</span>
                      <span className="cross" onClick={handleCloseIncorrectCredentialsAlert}>✖</span>
                    </div> : <></>}
                  </div>
                  <div className="inputs">
                      <div className="input-wrapper">
                          <input type="text" className={`input ${emailValid ? 'valid' : 'invalid'}`} disabled={submit} value={email} onChange={handleEmailInput} placeholder={constants.email_hint} />
                          <span className={`input-error ${emailValid ? 'valid' : 'invalid'}`}>{constants.email_error}</span>
                      </div>
                      <div className="input-wrapper">
                          <input type="password" className={`input ${passwordValid ? 'valid' : 'invalid mb-55'}`} disabled={submit} value={password} onChange={handlePasswordInput} placeholder={constants.password_hint} />
                          <span className={`input-error ${passwordValid ? 'valid' : 'invalid'}`}>{constants.password_error}</span>
                      </div>
                  </div>

                  <button type="button" className="yellow" onClick={handleLogin}>{constants.login_btn}</button>
                  <button type="button" className="white" onClick={handleRegister}>{constants.register_btn}</button>
                  <a className="forgot-pass" onClick={handleForgotPass}>{constants.forgot_pass}</a>
              </div>

              <Waves />
          </div>
      </div>
      </div>
      );
}

export default Login;
