import React from 'react'
import { useStyles } from './HeroV2Styles'
import { Row, TextContent, ReadMore } from '../ui'
import Container from '@material-ui/core/Container'
import { constants } from './HeroV2Constants'
import { ReactComponent as ExtensionsSvg } from '../../resources/extensions.svg'
import codeImg from '../../resources/run_final.gif'


const HeroV2 = () => {
	const classes = useStyles()

	const textContent = (
		<div className={classes.contentWrapper}>
			<TextContent options={constants} />
			<div className={classes.readMoreWrapper}>
				<ReadMore links={constants.links} />
			</div>
		</div>
	)

	const coverCard = (
		<div className={classes.coverCard}>
        <img className={classes.heroImage} src={codeImg} width="145%" height="500%"/></div>
	)

	return (
		<section className={classes.wrapper}>
			<Container maxWidth='lg'>
				<Row leftColumn={() => textContent} rightColumn={() => coverCard} />
			</Container>
		</section>
	)
}

export { HeroV2 }
