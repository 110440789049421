import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles'
import { Layout, LayoutV2 } from './components/layout'
import Login from './components/login/login.js';
import Register from './components/register/register.js';
import Verify from './components/verify/verify.js';
import Reset from './components/reset/reset.js';
import { theme_main, theme_landing } from './theme'

//for user-friendly toasting
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SendEmailForReset from "./components/sendEmailForReset/SendEmailForReset";
toast.configure()
//

const pageHelpers = require('./utils/page.helpers');
const redirectUri = pageHelpers.parseQueryString('redirectUri');
const id = pageHelpers.parseQueryString('id');
const code = pageHelpers.parseQueryString('code');

const AUTH_TARGET = process.env.PUBLIC_URL + "/auth" || "https://saharacloud.com/auth";
const STAT_TARGET = process.env.PUBLIC_URL || "https://saharacloud.com";
const BASE_TARGET = process.env.PUBLIC_URL || "https://saharacloud.com";
const targets = {auth: AUTH_TARGET, stat: STAT_TARGET, base: BASE_TARGET};

function App() {

	const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
	const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

	//handler for resize event
	//capture new window dimensions
	const handleResize = (e) => {
		setWindowWidth(window.innerWidth);
		setWindowHeight(window.innerHeight);
	}

	//bind window resize handler to internal props
	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
	});

	return (
		<Router>
			<Switch>
				<Route path="/login" render={props => <Login targets={targets} redirectUri={redirectUri} toast={toast} dims={{ width: windowWidth, height: windowHeight }} {...props} />} />
				<Route path="/register" render={props => <Register targets={targets} toast={toast} dims={{ width: windowWidth, height: windowHeight }} {...props} />} />
				<Route path="/verify" render={props => <Verify targets={targets} id={id} toast={toast} dims={{ width: windowWidth, height: windowHeight }} {...props} />} />
				<Route path="/reset" render={props => <Reset targets={targets} id={id} code={code} toast={toast} dims={{ width: windowWidth, height: windowHeight }} {...props} />} />
				<Route path="/resetPassword" render={props => <SendEmailForReset targets={targets} id={id} code={code} toast={toast} dims={{ width: windowWidth, height: windowHeight }} {...props} />} />

				<Route exact path='/'>
					<ThemeProvider theme={theme_main}>
						<LayoutV2 the_theme={theme_main} />
					</ThemeProvider>
				</Route>
				<Route exact path='/landing'>
					<ThemeProvider theme={theme_main}>
						<LayoutV2 the_theme={theme_landing} />
					</ThemeProvider>
				</Route>
        <Route exact path='/test'>
					<ThemeProvider theme={theme_main}>
						<LayoutV2 the_theme={theme_main} />
					</ThemeProvider>
				</Route>
			</Switch>
		</Router>
	)
}

export default App

