// for hardcoded values of send reset request page

const constants = {
  main: "Reset your Password",
  logo_text: "Sahara",
  email_hint: "Email address",
  email_error: "Invalid email address",
  email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  send_reset_request_btn: "Send password reset email",
  error_msg_reset1: "Please enter a valid email address.",
  error_msg_reset2: "Could not initiate password reset. Are you sure you entered the right email address?",
  success_email_send: "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.",
  return_to_sign_in: "Return to sign in",
};

module.exports = {
    constants: constants
};
