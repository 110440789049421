import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		background: '#F6F9FC',
		paddingTop: 80,
		paddingBottom: 150,
		[theme.breakpoints.up('md')]: {
			paddingTop: 205,
			paddingBottom: 300,
		},
	},
	contentWrapper: {
		paddingLeft: theme.spacing(0),
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(10),
		},
	},
	coverCard: {
		'& svg': {
			width: '100%',
		},
	},
  imgWrapper: {
    position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		'& img': {
			alignSelf: 'self-start',
			border: '1px solid #B7ECFC',
			boxShadow: '12px 12px 11px 0 rgba(114,124,135,0.17)',
			borderRadius: 6,
			//width: '100%',
		},
  }
}))
