import React from 'react'
import { useStyles } from './CloudFirstStyles'
import { Row, Title, TextContent } from '../ui'
import { withTriangles } from '../hoc'
// import { ReactComponent as VSCode } from '../../resources/vs-logo.svg'
import codeImg from '../../resources/item_selection.gif'
import Container from '@material-ui/core/Container'
import { constants } from './CloudFirstConstants'

const CloudFirst = () => {
	const classes = useStyles()

	const coverElem = (


<div className={classes.coverWrapper}>
<img src={codeImg} alt='code' width="690"
		height="420" />
		</div>
	)

	const textContent = (
		<div className={classes.contentWrapper}>
			<TextContent options={constants} />
		</div>
	)

	return (
		<section className={classes.wrapper} id='howItWorks'>
			<div className={classes.title}>
				<Title
					component='h2'
					color={{ color: constants.styles.title.color }}
					align='center'
				>
					How it works
				</Title>
			</div>
			<Container maxWidth='lg'>
				<Row leftColumn={() => coverElem} rightColumn={() => textContent} />
			</Container>
		</section>
	)
}

export default withTriangles({ topTriangle: true, bottomTriangle: true })(
	CloudFirst
)
