import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	title_big: {
		fontSize: 18,
		fontWeight: 600,
		letterSpacing: 0.69,
		marginTop: 0,
		marginBottom: 25,
		color: theme.palette.background.paper,
		[theme.breakpoints.up('sm')]: {
			fontSize: 24,
			marginTop: 50,
		},
	},
	title_small: {
		fontSize: 18,
		fontWeight: 300,
		letterSpacing: 0.52,
		margin: 0,
		marginBottom: 40,
		'& > p': {
			margin: 0,
		},
	},
	media: {
		fontSize: 18,
		letterSpacing: 0.47,
		color: theme.palette.background.paper,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	copyright: {
		opacity: 0.5,
		fontSize: 18,
		fontWeight: 300,
		letterSpacing: 0.47,
		lineHeight: '24px',
		width: '230px',
		textAlign: 'center',
		margin: 0,
		padding: '50px 0',
		color: theme.palette.background.paper,
		[theme.breakpoints.up('sm')]: {
			width: '100%',
		},
	},
}))
