import React from "react";
import './waves.scss';

const Waves = () => (
  <div className="waves-container">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none">
      <defs>
        <linearGradient id="gradient-one" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--color-top)" />
          <stop offset="60%" stopColor="var(--color-top)" />
          <stop offset="100%" stopColor="var(--color-bottom)" />
        </linearGradient>
        <linearGradient id="gradient-two" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--color-top)" />
          <stop offset="60%" stopColor="var(--color-top)" />
          <stop offset="100%" stopColor="var(--color-bottom)" />
        </linearGradient>
        <linearGradient id="gradient-three" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--color-top)" />
          <stop offset="80%" stopColor="var(--color-top)" />
          <stop offset="100%" stopColor="var(--color-bottom)" />
        </linearGradient>
      </defs>

      <path className='one' d="M-40.34,30.09 C148.69,124.83 329.28,-45.88 547.11,59.70 L500.00,150.00 L0.00,150.00 Z" />
      <path className='two' d="M-124.43,149.50 C466.42,-85.36 325.33,290.63 678.04,16.28 L650.96,260.03 L0.00,150.00 Z" />
      <path className='three' d="M-14.95,83.38 C149.99,150.00 428.04,39.95 585.50,106.08 L500.00,150.00 L0.00,150.00 Z" />
      <path className='four' d="M-62.36,167.27 C153.78,-3.45 375.56,215.63 574.77,71.53 L650.96,260.03 L0.00,150.00 Z" />
    </svg>
  </div>
);

export default Waves;
