import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		background: '#fff',
		paddingBottom: 73,
		[theme.breakpoints.up('lg')]: {
			paddingBottom: 146,
		},
	},
	titleWrapper: {
		marginBottom: 45,
		[theme.breakpoints.up('lg')]: {
			marginBottom: 90,
		},
	},
	ul:{
		textAlign:'left',
	}
}))
