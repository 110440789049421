// component for verify

import React from 'react';
import { constants } from './verify_constants.js';
import sty from './verify_styles.js';

import './verify.scss';

//import images
import cloud from '../../resources/cloud.png';
import Waves from "../waves/waves";

const requestFunctions = require('../../utils/request.helpers');

function Verify (props) {

    const [code, setCode] = React.useState("");
    const [codeValid, setCodeValid] = React.useState(true);
    const [newLoad, setNewLoad] = React.useState(true);
    const [submit, setSubmit] = React.useState(false);

    //parent passes base URL for auth
    //parent passes redirect uri from query string if present
    const targets = props.targets;
    const id = props.id;
    const dims = props.dims;
    const toast = props.toast;

    const styles = sty(dims.width, dims.height);

    //validate inputted code
    const handleCodeInput = (e) => {
        let n_code = e.target.value,
            isValid = n_code.match(constants.code_regex) != undefined;

        setCode(n_code);
        setCodeValid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //handler for code submission
    const handleSubmit = (e) => {
        if (!newLoad && codeValid) {
            setSubmit(true);
            let body = {userId: id, code: code},
            targ = targets.auth + "/verify";

            requestFunctions.makeRequestBody("PUT", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
                .then((body) => {
                    toast.success(constants.success_msg_verify, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false
                    });

                    let targ = targets.stat + "/login";
                    setTimeout(function () { window.location.replace(targ); }, 5000);
                })
                .catch((err) => {
                    setSubmit(false);
                    toast.error(constants.error_msg_code2, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false
                    });
                });
        }
        else {
            toast.error(constants.error_msg_code1, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
            });
        }
    }

    //handler for code re-submission
    const handleResend = (e) => {
        let body = {userId: id},
            targ = targets.auth + "/verify";

        requestFunctions.makeRequestBody("POST", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
            .then((body) => {
                toast.success(constants.success_msg_resend, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false
                });
            })
            .catch((err) => {
                toast.error(constants.error_msg_resend, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false
                });
            });
    }

    return (
        <div className="login-index">
        <div className="page verify">
            <div className="center-container">
                <div className="logo-wrapper">
                    <img src={cloud} alt="cloud" className="logo-cloud"/>
                    <span className="logo-text">{constants.logo_text}</span>
                </div>
                <p className="title">{constants.main}</p>
                <div className="form">
                    <div className="inputs">
                        <div className="input-wrapper">
                            <input type="text" className={`input ${codeValid ? 'valid' : 'invalid'}`} disabled={submit} value={code} onChange={handleCodeInput} placeholder={constants.code_hint} />
                            <span className={`input-error ${codeValid ? 'valid' : 'invalid'}`}>{constants.code_error}</span>
                        </div>
                    </div>
                    <button type="button" className="yellow" onClick={handleSubmit} >{constants.submit_btn}</button>
                    <button type="button" className="white" onClick={handleResend} >{constants.resend_btn}</button>
                </div>
            </div>
            <Waves />
        </div>
        </div>
      );
}

export default Verify;
