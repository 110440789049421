import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Title } from './Title'
import { Link } from './Link'
import { NormalLink } from './NormalLink'
import { SlackLink } from './SlackLink'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	subhead: {
		color: (props) => props.subHead.color,
		fontWeight: (props) => props.subHead.fontWeight,
		fontSize: '18px',
	},
	titleWrapper: {
		marginBottom: theme.spacing(2)
	},
	descr: {
		color: (props) => props.descr.color,
		opacity: 0.7,
		fontSize: 18,
		letterSpacing: '1.01px',
		lineHeight: '29px',
		marginBottom: 27,
		[theme.breakpoints.up('lg')]: {
			whiteSpace: 'pre-line',
		},
	},
  rowC: {
    display: "flex",
    "flex-direction": "row",
  },
}))

const styles = {
  normal_link: {
    marginRight: "auto",
  },
  slack_link: {
    marginRight: "auto",
  }
}

const TextContent = ({ options }) => {
	const classes = useStyles(options.styles)

	return (
		<div className={classes.root}>
			<strong className={classes.subhead}>{options.subhead}</strong>
			<div className={classes.titleWrapper}>
				<Title component='h2' color={{ color: options.styles.title.color }}>
					{options.head}
				</Title>
			</div>
			<p className={classes.descr}>{options.descr}</p>

			{options.link && <Link href={options.link.to} styles={options.styles.link}>
				{options.link.text}
			</Link>}

      {!options.link && <Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
        {options.link_1 && <NormalLink href={options.link_1.to} styles={{...options.styles.link, ...styles.normal_link}}>
          {options.link_1.text}
        </NormalLink>}
			</Grid>
			<Grid item xs={12} sm={6}>
        {options.link_2 && <SlackLink href={options.link_2.to} styles={{...options.styles.link, ...styles.slack_link}}>
          &nbsp; Join our <b> Slack </b>
        </SlackLink>}
			</Grid>
		</Grid>}

		</div>
	)
}

TextContent.propTypes = {
	options: PropTypes.object,
}

export { TextContent }
