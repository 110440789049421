import React, { useState } from 'react'
import { Header } from '../header'
import { Sidebar } from '../sidebar'
import { Hero } from '../hero'
import { CloudFirst } from '../cloudFirst'
import { VirtualVariety } from '../virtualVariety'
import { Hardware } from '../hardware'
import { Pricing } from '../pricing'
import { Join } from '../join'
import { Footer } from '../footer'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	layout: {
		fontFamily: theme.typography.fontFamily,
	},
}))

export const Layout = ({the_theme}) => {
	const [menuOpen, setMenuOpen] = useState(false)
	const handleStateChange = (state) => setMenuOpen(state)

	const classes = useStyles()

	return (
		<div className={classes.layout}>
			<Header onMenuOpen={setMenuOpen} showHeader={the_theme.showHeader} />
			<Sidebar
				outerContainerId={'Main'}
				isOpen={menuOpen}
				onStateChange={(state) => handleStateChange(state.isOpen)}
				setMenuOpen={setMenuOpen}
			/>
			<Hero />
			<CloudFirst />
			<VirtualVariety />
			<Hardware />
			<Pricing />
			<Join />
			<Footer />
		</div>
	)
}
