// helper functions to create dynamic displays

const BASE_WIDTH = 1536;
const BASE_HEIGHT = 754;

//method to compute greatest common divisor of 2 numbers
function gcd (a, b) {
  let x = Math.abs(a);
  let y = Math.abs(b);
  while (y) {
    let t = y;
    y = x % y;
    x = t;
  }
  return x;
}

//computes the scale factor of the provided dimension relative to
//the base width or height
function scale (dim, isWidth) {
    return (isWidth) ? (dim / BASE_WIDTH) : (dim / BASE_HEIGHT);
}

//compute % for dimension and return as string
//from a base percentage (relative to base dimensions)
//and a scale factor
function setDim (base, scale) {
    return (base * scale).toFixed(2).toString() + "%";
}

function fontSize (base, scale) {
    return ((100 * (base / BASE_WIDTH)) * scale).toString() + "vw";
}

module.exports = {
    gcd: gcd,
    scale: scale,
    setDim: setDim,
    fontSize: fontSize
};