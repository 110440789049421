import { palette } from '../../theme/palette'

export const constants = {
	styles: {
		title: {
			color: palette.colors.purple,
		},
	},
	priceCards: [
		{
			isActive: false,
			rate: 'Community/Education',
			price: { value: '$0', per: 'Free!', styles: { color: palette.colors.pink } },
			list: [
				'Simulated Devices Only',
				'Public and Private Projects',
				'Project Sharing & Embeds'
			],
			link: {
				to: 'https://saharacloud.io/register',
				text: 'Try for Free',
				styles: {
					background: palette.gradients.pinkGradient,
					boxShadow: palette.shadows.pinkShadow,
				},
			},
		},
		{
			isActive: true,
			rate: 'Professional',
			price: { value: '$200', per: 'Per Month', styles: { color: palette.colors.blue } },
			list: [
				`Bare-metal, Emulated, And Simulated Devices`,
				'Public and Private Projects',
				'Sharing of Projects',
				'API Integration'
			],
			link: {
				to: 'https://saharacloud.io/register',
				text: 'Start Building Today',
				styles: {
					background: palette.gradients.blueGradient,
					boxShadow: palette.shadows.blueShadow,
				},
			},
		},
		{
			isActive: false,
			rate: 'Custom',
			price: { value: 'Quote', per: '-', styles: { color: palette.colors.yellow } },
			list: [
				`Bare-metal, Emulated, And Simulated Devices`,
				'Public and Private Projects',
				'Sharing of Projects',
				`API Integration`,
				`Tailored hardware and infrastructure`,
			],
			link: {
				to: 'mailto: jama@saharacloud.io',
				text: 'Contact Us',
				styles: {
					background: palette.gradients.yellowGradient,
					boxShadow: palette.shadows.yellowShadow,
				},
			},
		},
	],
}
