import { createTheme } from '@material-ui/core/styles'
import { palette } from './palette'

const theme_main = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette,
})

const theme_landing = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette,
})

theme_main.showHeader = true;
theme_landing.showHeader = false;

export {
  theme_main,
  theme_landing,
}
