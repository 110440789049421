// component for register

import React from 'react';
import { useStyles } from '../header/HeaderStyles'
import { constants } from './register_constants.js';
import logo from '../../resources/logo.png'
import sty from './register_styles.js';
import Waves from "../waves_custom/waves";
import Box from '@material-ui/core/Box'
import { createHref } from '../../utils'
import { Link } from 'react-scroll'
import { Row, TextContent, ReadMore } from '../ui'
import Grid from '@material-ui/core/Grid'; // Grid version 1

//import images
import cloud from '../../resources/cloud.png';
import code from '../../resources/Adv-Testing-Short-Gif.gif';

import './register.scss';

import Container from '@material-ui/core/Container'

const requestFunctions = require('../../utils/request.helpers');

function Register (props) {
    const classes = useStyles()

    const [name, setName] = React.useState("");
    const [nameValid, setNameValid] = React.useState(true);
    const [email, setEmail] = React.useState("");
    const [emailValid, setEmailValid] = React.useState(true);
    const [password1, setPassword1] = React.useState("");
    const [password1Valid, setPassword1Valid] = React.useState(true);
    const [password2, setPassword2] = React.useState("");
    const [password2Valid, setPassword2Valid] = React.useState(true);
    const [newLoad, setNewLoad] = React.useState(true);
    const [submit, setSubmit] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(constants.error_msg2);


    //parent passes base URL for auth
    //parent passes redirect uri from query string if present
    const targets = props.targets;
    const dims = props.dims;
    const toast = props.toast;

    const styles = sty(dims.width, dims.height);

    //validate inputted name
    const handleNameInput = (e) => {
        let n_name = e.target.value,
            isValid = n_name.length > 0;

        setName(n_name);
        setNameValid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //validate inputted email
    const handleEmailInput = (e) => {
        let n_email = e.target.value,
            isValid = n_email.match(constants.email_regex) != undefined;

        setEmail(n_email);
        setEmailValid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //validate inputted password
    const handlePassword1Input = (e) => {
        let n_pass = e.target.value,
            isValid = n_pass.match(constants.password_regex) != undefined;

        setPassword1(n_pass);
        setPassword1Valid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //validate inputted password
    const handlePassword2Input = (e) => {
        let n_pass = e.target.value,
            isValid = n_pass === password1;

        setPassword2(n_pass);
        setPassword2Valid(isValid);
        if (newLoad) { setNewLoad(false); }
    }

    //handler for user registration
    const handleRegister = (e) => {
        if (!newLoad && nameValid && emailValid && password1Valid && password2Valid) {
            setSubmit(true);
            let body = {userName: name, userEmail: email, userPassword: password1},
            targ = targets.auth + "/user",
            id;

            console.log(targ)

            requestFunctions.makeRequestBody("POST", targ, {"User-Agent": "sahara-web", "Content-Type": "application/json"}, body)
                .then((body) => {
                    let b = (typeof body == "string") ? JSON.parse(body) : body;
                    id = b.userId;

                    let targ = targets.stat + "/verify?id=" + id;
                    setTimeout(function () { window.location.replace(targ); }, 5000);
                })
                .catch((err) => {
                    setSubmit(false);
                    setHasError(true);
                    setErrorMessage(constants.error_msg2)
                });
        } else {
          setHasError(true);
          setErrorMessage(constants.error_msg1)
        }
    }

    //handler for login
    //redirect to login page
    const handleLogin = (e) => {
        window.location.replace(targets.stat + "/login");
    }

    const handleCloseAlert = (e) => {
      setHasError(false);
    }

    return (
        <div className="login-index">

        <Container maxWidth='lg'>
					<header className={classes.header}>
						<a href='/' className={classes.logo}>
							<img src={logo} alt='logo' />
						</a>
						<nav className={classes.nav}>
						</nav>
					</header>
				</Container>

        <Grid container rowSpacing={1} columnSpacing={2} >

        <Grid item xs={12} sm={7}>
          <div className="page register">
            <div className="register-right">
             <img src={code} alt="code" className="code-img"/>
            </div>
          </div>
        </Grid>

          <Grid item xs={12} sm={5}>
            <div className="page register">
              <div className="register-left">
                <p className="main-title">{constants.right_main}</p>
                <div className="form">
                  <div className="invalid-credentials-block">
                    {hasError ? <div className="invalid-credentials">
                      <span className="invalid-credentials-text">{errorMessage}</span>
                      <span className="cross" onClick={handleCloseAlert}>✖</span>
                    </div> : <></>}
                  </div>

                    <div className="inputs">
                        <div className="input-wrapper">
                            <input type="text" className={`input ${nameValid ? 'valid' : 'invalid'}`} disabled={submit} value={name} onChange={handleNameInput} placeholder={constants.name_hint} />
                            <span className={`input-error ${nameValid ? 'valid' : 'invalid'}`} >{constants.name_error}</span>
                        </div>
                        <div className="input-wrapper">
                            <input type="text" className={`input ${emailValid ? 'valid' : 'invalid'}`} disabled={submit} value={email} onChange={handleEmailInput} placeholder={constants.email_hint} />
                            <span className={`input-error ${emailValid ? 'valid' : 'invalid'}`}>{constants.email_error}</span>
                        </div>
                        <div className="input-wrapper">
                            <input type="password" className={`input ${password1Valid ? 'valid' : 'invalid mb-55'}`} disabled={submit} value={password1} onChange={handlePassword1Input} placeholder={constants.password1_hint} />
                            <span className={`input-error ${password1Valid ? 'valid' : 'invalid'}`}>{constants.password1_error}</span>
                        </div>
                        <div className="input-wrapper">
                            <input type="password" className={`input ${password2Valid ? 'valid' : 'invalid'}`} disabled={submit} value={password2} onChange={handlePassword2Input} placeholder={constants.password2_hint} />
                            <span className={`input-error ${password2Valid ? 'valid' : 'invalid'}`}>{constants.password2_error}</span>
                        </div>
                    </div>

                    <button type="button" className="yellow" onClick={handleRegister}>{constants.register_btn}</button>
                    <button type="button" className="white" onClick={handleLogin}>{constants.login_btn}</button>
                </div>
              </div>
            </div>
          </Grid>
          <Waves />
        </Grid>
      </div>
      );
}

export default Register;
