export const constants = {
	head: 'Select From Our Library of Bare-metal, Emulated, and Simulated Devices',
	subhead: 'Instant Accessibility',
	descr: `Create a simple circuit, transmit code to an LCD using an Arduino, or write Verilog on FPGAs for Image Processing. \
	 Our infrastructure supports bare-metal, emulated, and simulated devices. Don't see the devices or peripherals that you need? We've got you covered.`,
	link: { to: 'https://saharacloud.io/register', text: 'Build Your System Now' },
	styles: {
		subHead: {
			color: '#67E5FF',
			fontWeight: 'bold',
		},

		descr: {
			color: '#32325C',
			fontWeight:'bold',
		},
		title: {
			color: '#32325C',
		},
		link: {
			background: `linear-gradient(180deg, #A0EFFF 0%, #67E5FF 100%)`,
		},
	},
}
