import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

const Row = ({ leftColumn, rightColumn }) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={6}>
				{leftColumn()}
			</Grid>
			<Grid item xs={12} md={6}>
				{rightColumn()}
			</Grid>
		</Grid>
	)
}

Row.propTypes = {
	leftColumn: PropTypes.func.isRequired,
	rightColumn: PropTypes.func.isRequired,
}

export { Row }
