import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	priceCard: {
		padding: '54px 24px 58px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: theme.palette.colors.lilac,
		width: '100%',
		maxWidth: 394,
		position: 'relative',
		'&.active': {
			boxShadow: '0 7px 24px 0 rgba(83,83,83,0.1)',
			'&:after': {
				content: '""',
				background: (props) => props.price.styles.color,
				height: 2,
				display: 'block',
				position: 'absolute',
				left: 0,
				right: 0,
				top: 0,
			},
		},
	},
	rate: {
		color: theme.palette.colors.purple,
		marginBottom: theme.spacing(3),
		fontSize: 24,
		fontWeight: 'bold',
		letterSpacing: '0.27px',
		lineHeight: '32px',
	},
	price: {
		color: (props) => props.price.styles.color,
		marginBottom: theme.spacing(3),
		fontSize: 72,
		fontWeight: 600,
	},
	span: {
		marginBottom: theme.spacing(6),
	},
	ul: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		marginBottom: theme.spacing(8),
		'& li': {
			whiteSpace: 'pre-line',
			textAlign: 'center',
			lineHeight: '28px',
			fontSize: 14,
			'&:not(:last-child)': {
				marginBottom: theme.spacing(2),
			},
		},
	},
	linkWrapper: {
		marginTop: 'auto',
	},
}))
