import React, { useState } from 'react'
import { useStyles } from './FormStyles'
import { constants } from './FormConstants'
import PropTypes from 'prop-types'

export const Form = ({ styles }) => {

	const [formSubmitted, setFormSubmitted] = useState(false)

	const handleSubmit = event => {
	  event.preventDefault()
	  const data = new FormData(event.target)
	  fetch("https://saharacloud.activehosted.com/proc.php", {
		method: "POST",
		body: data,
		mode: "no-cors",
	  })
		.then(res => {
		  console.log(res)
		  setFormSubmitted(true)
		  /*setTimeout(() => {
			setFormSubmitted(false)
		  }, 5000)*/


		  document.getElementsByClassName('typeform-share button')[0].click();


		})
		.catch(error => console.log("Request failed", error))
	}

	//form styles
	const classes = useStyles(styles)
	//handle email value capture
	const [emailValid, setEmailValid] = React.useState(true)
	const [newLoad, setNewLoad] = React.useState(true)

	const handleEmailInput = (e) => {
		let n_email = e.target.value,
			isValid = n_email.match(constants.email_regex) != undefined

		setEmailValid(isValid)
		if (newLoad) {
			setNewLoad(false)
		}
	}

	return (
		/*<form className={classes.form}>
			<input
				id='email-text'
				className={classes.input}
				placeholder='Enter email'
				onChange={handleEmailInput}
			/>
			<button
				id='email-submit'
				className={classes.button}
				onClick={handleMailClick}
				type='button'
				disabled={newLoad || !emailValid}
			>
				Get Early Access
			</button>
		</form>*/

	<form className={classes.form} onSubmit={e => handleSubmit(e)}>
      <input type="hidden" name="u" value="1" />
      <input type="hidden" name="f" value="1" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />

			<input
				type="text"
				name="email"
				id='email'
				className={classes.input}
				placeholder='Enter email'
				onChange={handleEmailInput}
				required
			/>

			<button
				id='_form_1_submit'
				className={formSubmitted?classes.button_submitted:classes.button_normal}
				type='submit'
				disabled={newLoad || !emailValid}
			>
				{formSubmitted? "Submitted!" : "Start Today" }
			</button>

    </form>


	)
}

Form.propTypes = {
	styles: PropTypes.object,
}
