// for hardcoded values of login page

const constants = {
  left_main1: "Welcome to Sahara!",
  left_main2: "Virtual Hardware Development in the Cloud",
  logo_text: "Sahara",
  login_big_txt: "Sign In",
  login_small_txt: "Please enter your user credentials.",
  email_hint: "Email address",
  email_error: "Invalid email address",
  password_hint: "Password",
  password_error: "Invalid password. Must contain 1 lowercase, uppercase, numeric, special character. Minimum length is 8 characters.",
  login_btn: "Sign In",
  register_btn: "Create Account",
  email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password_regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  forgot_pass: "Forgot Password?",
  error_msg_login1: "Please fill out all required information.",
  error_msg_login2: "Login failed! Please ensure credentials are correct",
  error_msg_reset1: "Please enter a valid email address.",
  error_msg_reset2: "Could not initiate password reset. Are you sure you entered the right email address?",
  success_msg_reset: "Please check your email for further password reset instructions.",
  incorrect_credentials: "Incorrect username or password"
};

module.exports = {
    constants: constants
};
