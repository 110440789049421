import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	burgerMenu: {
		width: 30,
		height: 25,
		cursor: 'pointer',
		marginLeft: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:before, &:after, i': {
			content: '""',
			display: 'block',
			height: 2,
			backgroundColor: theme.palette.background.paper,
		},
	},
}))

export const BurgerMenu = ({ onMenuOpen }) => {
	const classes = useStyles()

	return (
		<div
			id='header-burger'
			className={classes.burgerMenu}
			onClick={() => onMenuOpen(true)}
		>
			<i></i>
		</div>
	)
}
