export const constants = {
	head: 'Build and Debug Systems From Anywhere',
	descr: `Start R&D immediately by accessing both physical and virtual devices in the cloud`,
	link_1: { to: 'https://saharacloud.io/register', text: 'Start Building' },
  link_2: { to: 'https://join.slack.com/t/saharacloudcommunity/shared_invite/zt-1do4o7roi-~Fx7MO6f6HrZcjLwhRvBnQ' },

	links: {
		tools: {
			em: 'Prebuilt Integrations',
			p:
				'We tie in with popular tools like KiCad and Arduino to make development a breeze.',
			link: { to: 'https://saharacloud.io/register', text: 'Find Integrations' },
		},
		integrations: {
			em: 'Device SDK',
			p:
				'Don\'t see your chip? No problem. Use our SDK to support the hardware you want.',
			link: { to: 'https://saharacloud.io/register', text: 'Explore libraries' },
		},
	},
	styles: {
		subHead: {
			color: '#FE9AD3',
			fontWeight:'bold',
		},
		descr: {
			color: '#fff',
		},
		title: {
			color: '#fff',
		},
		link: {
			background: `linear-gradient(180deg, #FEAEDC 0%, #FE9AD3 100%);`,
		},
	}
}
