import { makeStyles } from '@material-ui/core/styles'

const logoWidth = 140

export const useStyles = makeStyles((theme) => ({
	header: {
		marginTop: 24,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			marginTop: 70,
		},
	},
	logo: {
		width: logoWidth,
		'& > img': {
			width: '100%',
		},
	},
	nav: {
		'& ul': { display: 'flex' },
		'& li:not(:last-child)': {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				marginRight: theme.spacing(5),
			},
		},
		'& a': {
			fontSize: 16,
			lineHeight: '22px',
			color: theme.palette.background.paper,
			textDecoration: 'none',
			cursor: 'pointer',
			[theme.breakpoints.up('sm')]: {
				fontSize: 25,
				lineHeight: '34px',
			},
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
}))
