// helper functions to make HTTP requests

const request = require('request');

//promise wrapper around request method
//for requests with no body (POST, PUT)
function makeRequestBody (method, uri, headers, body) {
    return new Promise(function(resolve, reject) {
        request({
            uri: uri,
            headers: (headers) ? headers : undefined,
            json: true,
            body: body,
            method: method
          }, function (err, res, body) {
            if (err) { return reject(err); }
            if (body.status === 0) { return reject(body.message.message || body.message); }
            return resolve(body.data);
         });
    });
}

//promise wrapper around request method
//for requests with no body (GET, DELETE)
function makeRequestNoBody (method, uri, headers) {
    return new Promise(function(resolve, reject) {
        request({
            uri: uri,
            headers: (headers) ? headers : undefined,
            method: method
          }, function (err, res, body) {
            if (err) { return reject(err); }
            if (body.status === 0) { return reject(body.message.message || body.message); }
            return resolve(body.data);
         });
    });
}

module.exports = {
    makeRequestBody: makeRequestBody,
    makeRequestNoBody: makeRequestNoBody
};