import { makeStyles, createStyles } from '@material-ui/core/styles'
import backgroundEmail from '../../resources/background-email.svg'

export const useStyles = makeStyles((theme) =>
	createStyles({
		wrapper: {
			padding: '110px 0 171px',
			background: `url("${backgroundEmail}") top center no-repeat`,
			backgroundSize: 'cover',
			backgroundPositionY: '100%',
		},
		box: {
			maxWidth: 336,
			[theme.breakpoints.up('lg')]: {
				maxWidth: 557,
			},
		},
		title: {
			fontSize: 36,
			fontWeight: 'bold',
			letterSpacing: '1.57px',
			lineHeight: '38px',
			marginBottom: theme.spacing(4),
			color: theme.palette.background.paper,
			[theme.breakpoints.up('lg')]: {
				fontSize: 60,
				lineHeight: '55px',
			},
		},
		descr: {
			fontSize: 18,
			fontWeight: 300,
			letterSpacing: '0.47px',
			lineHeight: '24px',
			textAlign: 'center',
			marginBottom: theme.spacing(4),
			color: theme.palette.background.paper,
			[theme.breakpoints.up('lg')]: {
				whiteSpace: 'pre-line',
			},
		},
	})
)
