import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	link: (props) => ({
		...props,
		alignSelf: 'self-start',
		padding: '15px 30px',
		borderRadius: '15px',
		color: '#1A1E2A',
		fontSize: '22px',
		fontWeight: 'bold',
		lineHeight: '30px',
		display: 'inline-block',
		textDecoration: 'none',
		'&:hover': {
			opacity: 0.85,
		},
	}),
}))

const NormalLink = ({ href, styles, children }) => {
	const classes = useStyles(styles)

	return (
		<a href={href} className={classes.link}>
			{children}
		</a>
	)
}

export { NormalLink }
