import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { useStyles } from './FooterStyles'
import { constants } from './FooterConstans'

export const Footer = () => {
	const classes = useStyles()
	return (
		<div className={`${classes.root}`}>
			<Container>
				<Grid container direction='column' justifyContent='center' alignItems='center'>
					<Grid item xs={11} sm={5}>
						<Box
							component='h5'
							className={classes.title_big}
							textAlign='center'
						>
							{constants.title_big}
						</Box>
					</Grid>
					<Grid container item xs={11} sm={5} justifyContent='space-between'>
						{constants.media.map((media, key) => (
							<a key={key} className={classes.media} href={media.link}>
								{media.name}
							</a>
						))}
					</Grid>
					<p className={classes.copyright}>{constants.copyright}</p>
				</Grid>
			</Container>
		</div>
	)
}
